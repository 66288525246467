
html {
  display:flex;
  width:100%;
  height:100%;
  background: black;
}

body {
  overflow: hidden;
  height: 100%;
  width: 100%;
 display:flex;
  flex:1;
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  margin: 0;
}

textarea, input { 
  -webkit-appearance: none;
  outline: none; }


@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(43, 43, 42, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 30px rgba(43, 43, 42, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(43, 43, 42, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(43, 43, 42, 0.4);
    box-shadow: 0 0 0 0 rgba(43, 43, 42, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 30px rgba(43, 43, 42, 0);
      box-shadow: 0 0 0 30px rgba(43, 43, 42, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(43, 43, 42, 0);
      box-shadow: 0 0 0 0 rgba(43, 43, 42, 0);
  }
}